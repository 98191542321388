/* ======================================
              ACTIONS NGRX
====================================== */

import { createAction, props } from '@ngrx/store';
import { AlertType } from '@shared/data-structure/custom-types';


// ACCIONES CONFIG
export const configAction = createAction(
  '[Config] Change Default',
  props<{ darkMode: boolean, language: string, currency: string }>()
);
export const darkModeAction = createAction(
  '[Config] Change Dark Mode',
  props<{ darkMode: boolean }>()
);
export const languageAction = createAction(
  '[Config] Change Language',
  props<{ language: string }>()
);
export const currencyAction = createAction(
  '[Config] Change Currency',
  props<{ currency: string }>()
);
export const divisaAction = createAction(
  '[Config] Change Divisas',
  props<{ dollar: number, euro: number }>()
);


// ACCIONES ALERTA
export const emitAlert = createAction(
  '[Alert] Emit Notification',
  props<{ message: string, alertType: AlertType }>()
);
export const resetAlert = createAction(
  '[Alert] Reset Notifications'
);


// ACCIONES USER
export const updateUser = createAction(
  '[User] Update',
  props<{ user: any }>()
);
export const resetUser = createAction(
  '[User] Reset'
);


// ACCIONES DATAPROVIDER
export const updateProviderRoles = createAction(
  '[DataProvider] Update Roles',
  props<{ roles: any }>()
);
export const updateProviderLM = createAction(
  '[DataProvider] Update LM',
  props<{ lm: any }>()
);
export const updateProviderTotalBooks = createAction(
  '[DataProvider] Update Total Books',
  props<{ totalBooks: number }>()
);


// REDUCERS SEACHER BOOKS
export const updateSearcherBooks = createAction(
  '[Searcher] Update Searcher Books',
  props<{
    text: string,
    lm: string,
    bookType: string,
    from: string,
    to: string,
    lastResults: any[],
    lastAuthors: any[],
    booksAndAuthors: any[],
    countAuthors: number,
    offset: number,
    totalResults: number,
    bookcodesPerPage: any,
  }>()
);
export const updateHighlight = createAction(
  '[Searcher] Update Highlight',
  props<{
    lastHighlight: string,
  }>()
);
export const resetHighlight = createAction(
  '[Searcher] Reset Highlight'
);


// REDUCERS SEACHER AUTHORS
export const updateSearcherAuthors = createAction(
  '[Searcher] Update Searcher Authors',
  props<{
    author: string,
    lastResults: any[],
    offset: number,
    totalResults: number,
  }>()
);
