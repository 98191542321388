/* ======================================
              REDUCERS NGRX
====================================== */

import { createReducer, on } from '@ngrx/store';

import {
  configAction, darkModeAction, languageAction, currencyAction,
  emitAlert, resetAlert,
  updateUser, resetUser,
  updateProviderRoles, updateProviderLM, updateProviderTotalBooks, divisaAction,
  updateSearcherBooks, updateHighlight, resetHighlight, updateSearcherAuthors
} from '../actions/actions-store-state.model';
import {
  initStoreStateConfig, initStoreStateAlert, initStoreStateUser, initStoreStateDataProvider,
  initStoreStateSeacherBooks, initStoreStateSeacherAuthors
} from '../store-state.model';


// REDUCERS CONFIG
export const reducerConfig = createReducer(
  initStoreStateConfig,
  on(configAction, (state, { darkMode, language, currency }) => {
    return {
      ...state,
      darkMode: darkMode,
      language: language,
      currency: currency,
    };
  }),
  on(darkModeAction, (state, { darkMode }) => {
    return {
      ...state,
      darkMode: darkMode,
    };
  }),
  on(languageAction, (state, { language }) => {
    return {
      ...state,
      language: language,
    };
  }),
  on(currencyAction, (state, { currency }) => {
    return {
      ...state,
      currency: currency,
    };
  }),
  on(divisaAction, (state, { dollar, euro }) => {
    return {
      ...state,
      dollar: dollar,
      euro: euro,
    };
  }),
);


// REDUCERS ALERTA
export const reducerAlert = createReducer(
  initStoreStateAlert,
  on(emitAlert, (state, { message, alertType }) => {
    return {
      ...state,
      message, alertType,
    };
  }),
  on(resetAlert, () => {
    return initStoreStateAlert;
  })
);


// REDUCERS USER
export const reducerUser = createReducer(
  initStoreStateUser,
  on(updateUser, (state, { user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(resetUser, () => {
    return initStoreStateUser;
  })
);


// REDUCERS DATAPROVIDER
export const reducerDataProvider = createReducer(
  initStoreStateDataProvider,
  on(updateProviderRoles, (state, { roles }) => {
    return {
      ...state,
      roles,
    };
  }),
  on(updateProviderLM, (state, { lm }) => {
    return {
      ...state,
      lm,
    };
  }),
  on(updateProviderTotalBooks, (state, { totalBooks }) => {
    return {
      ...state,
      totalBooks,
    };
  }),
);


// REDUCERS SEACHER BOOKS
export const reducerSearcherBooks = createReducer(
  initStoreStateSeacherBooks,
  on(updateSearcherBooks, (state, { text, lm, bookType, from, to, lastResults, lastAuthors, booksAndAuthors, countAuthors, offset, totalResults, bookcodesPerPage }) => {
    return {
      ...state,
      text, lm, bookType, from, to, lastResults, lastAuthors, booksAndAuthors, countAuthors, offset, totalResults, bookcodesPerPage
    };
  }),
  on(updateHighlight, (state, { lastHighlight }) => {
    return {
      ...state,
      lastHighlight
    };
  }),
  on(resetHighlight, () => {
    return initStoreStateSeacherBooks;
  })
);

// REDUCERS SEACHER AUTHORS
export const reducerSearcherAuthors = createReducer(
  initStoreStateSeacherAuthors,
  on(updateSearcherAuthors, (state, { author, lastResults, offset, totalResults }) => {
    return {
      ...state,
      author, lastResults, offset, totalResults
    };
  })
);


