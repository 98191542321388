import { Component, Inject, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { BucketS3Service } from '@shared/services/bucket-s3.service';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearcherApiService } from '@modules/searcher/searcher-api.service';
import { AuthorRoleService } from '@shared/services/author-role.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FunctionsService } from '@shared/services/functions.service';
import { IframePdfComponent } from '@shared/components/iframe-pdf/iframe-pdf.component';

@Component({
  selector: 'astrea-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit, OnDestroy {

  @HostBinding('class') public hostDarkMode: string = 'dark-mode';

  private subsDarkMode$!: Subscription;
  public loading = true;

  public loadingFile = {
    loading: false,
    type: ''
  };

  public book!: any;
  public isPublicDoctrine = false;

  constructor(
    public S3Service: BucketS3Service,
    public fnService: FunctionsService,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private refDialog: MatDialogRef<BookDetailsComponent>,
    private api: SearcherApiService,
    private authorRoleService: AuthorRoleService,
    private _t: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getBookDetails();
    this.isPublicDoctrine = this.data.bookcode.startsWith("9900") ? true : false;

    this.subsDarkMode$ = this.store.select(state => state.storeConfig.darkMode)
      .subscribe(dk => {
        dk ? this.hostDarkMode = 'dark-mode' : this.hostDarkMode = '';
      });
  }

  ngOnDestroy(): void {
    if (this.subsDarkMode$) this.subsDarkMode$.unsubscribe();
  }


  getBookDetails(): void {
    this.api.getBookDetails(this.data.bookcode)
      .subscribe(res => {
        this.book = res.result.data;
        this.loading = false;
      });
  }

  getFile(bucket: string, bookcode: string): void {
    this.loadingFile.loading = true;
    this.loadingFile.type = bucket;
    this.api.getFile({ bucket, bookcode })
      .then(pdf => {
        if (pdf.type.includes('application/json')) {
          this.alertFileError();
        } else if (pdf.type.includes('application/pdf')) {
          window.open(URL.createObjectURL(pdf), '_blank');
        }
        this.loadingFile.loading = false;
        this.loadingFile.type = '';
      })
      .catch(err => {
        this.alertFileError();
      });
  }


  buildAuthorsData(): string {
    const authors = this.authorRoleService.buildAuthorsAndRoles(this.book.authors, this.book.vAuthors);
    return authors.map(a => { return `${a.lastname}, ${a.firstname} (${a.role})` }).join(' - ');
  }

  buildLMData(): string {
    return this.book.vLM.map((a: any) => { return `${a.legalMatter}` }).join(' - ');
  }


  alertFileError(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.file_err'),
      alertType: 'danger'
    }));
  }

  public getBookType(booktype: string): string {
    switch (booktype) {
      case 'A' : case 'D' : { return this._t.instant('searcher.type.article'); }
      case 'M' : case 'J' : { return this._t.instant('searcher.type.magazine'); }
      case 'B' : { return this._t.instant('searcher.type.book'); }
      default : { return this._t.instant('searcher.type.book') }
    }
  }

  public getBookBinding(booktype: string): string | null {
    switch (booktype) {
      case 'E' : { return this._t.instant('searcher.binding.bound'); }
      case 'D' : { return this._t.instant('searcher.binding.doctrine'); }
      case 'R' : { return this._t.instant('searcher.binding.rustic_cover'); }
      default : { return null }
    }
  }

  closeModal(): void {
    this.refDialog.close();
  }

}
