/* ======================================
             INIT STATE NGRX
====================================== */

import { AlertType } from '@shared/data-structure/custom-types';
import { CURRENCY_WORTH } from '@shared/data-structure/currency-worth';


export interface StoreStateConfig {
  darkMode: boolean;
  language: string;
  currency: string;
  dollar: number;
  euro: number;
}
export interface StoreStateAlert {
  message: string;
  alertType: AlertType;
}
export interface StoreStateUser {
  user: any;
}
export interface StoreStateDataProvider {
  roles: any;
  lm: any;
  totalBooks: number | null;
}
export interface StoreStateSearcherBooks {
  text: string;
  lm: string;
  bookType: string;
  from: string;
  to: string;
  lastResults: any[];
  lastAuthors: any[];
  booksAndAuthors: any[];
  countAuthors: number;
  offset: number;
  totalResults: number;
  lastHighlight: string;
  bookcodesPerPage: any;
}
export interface StoreStateSearcherAuthors {
  author: string;
  lastResults: any[];
  offset: number;
  totalResults: number;
}

export const initStoreStateConfig: StoreStateConfig = {
  darkMode: false,
  language: 'es-ar',
  currency: 'ARS',
  dollar: CURRENCY_WORTH,
  euro: CURRENCY_WORTH,
};
export const initStoreStateAlert: StoreStateAlert = {
  message: '',
  alertType: '',
};
export const initStoreStateUser: StoreStateUser = {
  user: null,
};
export const initStoreStateDataProvider: StoreStateDataProvider = {
  roles: null,
  lm: null,
  totalBooks: null,
};
export const initStoreStateSeacherBooks: StoreStateSearcherBooks = {
  text: '',
  lm: '',
  bookType: '',
  from: '',
  to: '',
  lastResults: [],
  lastAuthors: [],
  booksAndAuthors: [],
  countAuthors: 0,
  offset: 0,
  totalResults: 0,
  lastHighlight: '',
  bookcodesPerPage: {},
}
export const initStoreStateSeacherAuthors: StoreStateSearcherAuthors = {
  author: '',
  lastResults: [],
  offset: 0,
  totalResults: 0,
}
